import React, { useEffect, useState } from "react";
import "./Select.css";
import Cookies from "universal-cookie";
const axios = require("axios");

const Uno = () => {
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState("");
  const [emailsent, setEmailSent] = useState("");
  function getData() {
    axios
      .get("https://www.lae-edu.com/api/get-chat-questions")
      .then(function (response) {
        // handle success
        setData(response.data.questions);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }

  function postData(email) {
    axios
      .post("https://www.lae-edu.com/api/set-chat-questions", {
        email: email,
        field_database: data[0]?.field_database,
        value: selected,
      })
      .then(function (response) {
        alert("Your message has been sent succesfully");
      })
      .catch(function (error) {
        alert("We have troubles to sent your message");
      });
  }
  const cookies = new Cookies();

  const getInfoLocal = () => {
    const userEmail = cookies.get("send"); 
    console.log("aqui", userEmail); 
    setEmailSent(userEmail);  
  };

  useEffect(() => {
    getData();
    getInfoLocal();
  }, []);

  const handleChange = (e) => {
    setSelected(e.target.value);
  };

  const handleSubmit = (e) => {
    alert("Your select answer is: " + selected); 
    postData(emailsent);
    e.preventDefault();
  }; 

  return (    
      <form className="body" onSubmit={handleSubmit}>
        <label className="label">{data[0]?.es}</label>
        <select onChange={handleChange} className="select">
       <option>seleccione una opción</option> 
          {data[0]?.answers.map((res) => (
            <option key={res.id} value={res.field_id}>
              {res.es}
            </option>
          ))}
        </select>
        <input type="submit" value="Enviar" className="btnInput" />
      </form>    
  );
};

export default Uno;
