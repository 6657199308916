import React from "react"
import { Route } from "wouter";
import Uno from "./components/Uno"
import Dos from "./components/Dos"
import Tres from "./components/Tres"
import Cuatro from "./components/Cuatro"
import Cinco from "./components/Cinco"
import Seis from "./components/Seis"
import Siete from "./components/Siete"
import Cero from "./components/Cero"

function App() {
  return (
    <React.Fragment>          
      <Route exact path="/" component={Uno} />
      <Route exact path="/2" component={Dos} /> 
      <Route exact path="/3" component={Tres} />
      <Route exact path="/4" component={Cuatro} />
      <Route exact path="/5" component={Cinco} />
      <Route exact path="/6" component={Seis} />
      <Route exact path="/7" component={Siete} />
      <Route exact path="/confirm" component={Cero} />
    </React.Fragment>
  );
}

export default App;
