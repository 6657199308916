import React, { useEffect, useState } from "react";
import "./Select.css";
import Cookies from "universal-cookie";


const Cero = () => {
  
  const [selected, setSelected] = useState("");   
  
  const cookies = new Cookies();

  const handleChange = (e) => {
    setSelected(e.target.value);   
  };

  const handleSubmit = (e) => {
    alert("Your select answer is: " + selected);
    if(selected.length){
      cookies.set('send', selected, {path:'/'});
    } 
    e.preventDefault();
  }; 

  return (    
      <form className="body" onSubmit={handleSubmit}>
        <label className="label">Por favor confirme su correo electronico</label>
        <input onChange={handleChange} className="select"></input>
        <button type="submit" className="btnSubmit" onClick={handleSubmit}>Enviar</button>
      </form>    
  );
};

export default Cero;
